import CryptoJS from "crypto-js";

export default {
  //加密方法
  aes(word) {
    const key = CryptoJS.enc.Utf8.parse("test");
    const iv = CryptoJS.enc.Utf8.parse("test");
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return CryptoJS.MD5("a111111").toString();
  },
  //  解密方法
  //  data：要解密的字符串
  //  key：加密因子
  //  iv：偏移量
  adse(data) {
    let d = JSON.stringify(data);
    let keyStr = "a4bcabc3fc22a1ee1a1155d696741a44";
    let ivStr = "a4bcabc3fc22a1ee1a1155d696741a44";
    let key = CryptoJS.enc.Utf8.parse(keyStr);
    let iv = CryptoJS.enc.Utf8.parse(ivStr);
    let srcs = CryptoJS.enc.Utf8.parse(d);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString(CryptoJS.format.Hex);

    // return encrypted.toString();

    //
    // const key = Cryptojs.enc.Utf8.parse('a4bcabc3fc22a1ee1a1155d696741a44'); // 加密因子
    // const iv = Cryptojs.enc.Utf8.parse('a4bcabc3fc22a1ee1a1155d696741a44'); // 偏移量
    // let encryptedHexStr = Cryptojs.enc.Hex.parse(data);
    //
    // let str = Cryptojs.enc.Utf8.stringify(encryptedHexStr)
    //
    // let decrypted = CryptoJSES.decrypt(str, key, {
    //   iv: iv,
    //   mode: Cryptojs.mode.CBC, // AES加密模式
    //   padding: Cryptojs.pad.Pkcs7 // 填充:  pkcs7 paddin
    // })
    // return decrypted.toString()
  },
};
