import CryptoJS from "crypto-js";

var countDecimals = function (num) {
  var len = 0;
  try {
    num = Number(num);
    var str = num.toString().toUpperCase();
    if (str.split("E").length === 2) {
      var isDecimal = false;
      if (str.split(".").length === 2) {
        str = str.split(".")[1];
        if (parseInt(str.split("E")[0]) !== 0) {
          isDecimal = true;
        }
      }
      let x = str.split("E");
      if (isDecimal) {
        len = x[0].length;
      }
      len -= parseInt(x[1]);
    } else if (str.split(".").length === 2) {
      if (parseInt(str.split(".")[1]) !== 0) {
        len = str.split(".")[1].length;
      }
    }
  } catch (e) {
    throw e;
  } finally {
    if (isNaN(len) || len < 0) {
      len = 0;
    }
    return len;
  }
};
var convertToInt = function (num) {
  num = Number(num);
  var newNum = num;
  var times = countDecimals(num);
  var temp_num = num.toString().toUpperCase();
  if (temp_num.split("E").length === 2) {
    newNum = Math.round(num * Math.pow(10, times));
  } else {
    newNum = Number(temp_num.replace(".", ""));
  }
  return newNum;
};
var getCorrectResult = function (type, num1, num2, result) {
  var temp_result = 0;
  switch (type) {
    case "add":
      temp_result = num1 + num2;
      break;
    case "sub":
      temp_result = num1 - num2;
      break;
    case "div":
      temp_result = num1 / num2;
      break;
    case "mul":
      temp_result = num1 * num2;
      break;
  }
  if (Math.abs(result - temp_result) > 1) {
    return temp_result;
  }
  return result;
};
export default {
  meiqiaShow(metadata = {}) {
    // metadata=  {
    //   address; // 地址
    //   age; // 年龄
    //   comment; // 备注
    //   email; // 邮箱
    //   gender; // 性别
    //   name; // 名字
    //   qq; // QQ
    //   tel; // 电话
    //   weibo; // 微博
    //   weixin; // 微信
    //   contact; // 联系人
    // }
    _MEIQIA("showPanel");
    if (Object.keys(metadata).length > 0) {
      _MEIQIA("metadata", metadata);
    }
  },
  //输入金额正则过滤
  amountFloat(val) {
    let sNum = val.toString(); //先转换成字符串类型
    if (sNum.indexOf(".") == 0) {
      //第一位就是 .

      sNum = "0" + sNum;
    }
    sNum = sNum.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
    sNum = sNum.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
    sNum = sNum.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    sNum = sNum.replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, "$1$2.$3"); //只能输入4个小数
    //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    if (sNum.indexOf(".") < 0 && sNum != "") {
      sNum = parseFloat(sNum);
    }
    return sNum;
  },
  //输出n位小数
  decal(num, n) {
    if (num) {
      const index = num.toString().indexOf(".");
      if (index > -1) {
        return Number(num.toString().substring(0, index + n + 1));
      } else {
        return num;
      }
    } else if (n > 0) {
      let str = "0.";
      for (let i = 0; i < n; i++) {
        str += "0";
      }
      return str;
    } else {
      return num;
    }
  },
  //输出2位小数
  decal2(num) {
    if (num != undefined) {
      let ns = "";
      if (num >= 0) {
        ns = Math.floor(num * 100) / 100;
      } else {
        // let s = num.substring(num.indexOf('-')+1, num.length)

        // ns = '-' + Math.floor(s * 100) / 100;
        ns = Math.floor(num * 100) / 100;
      }
      return ns;
    }
  },
  //输出2位小数
  decal4(num) {
    // if(num != undefined){
    //   let ns = Math.floor(num * 100) / 100
    //   return  ns
    // }

    if (num != undefined) {
      var n = "";
      if (num > 0) {
        num = Number(num);
      } else {
        num = Number(num) * -1;
      }
      var times = 0,
        s1 = num.toString();
      try {
        times += countDecimals(s1);
      } catch (e) {}
      var result = (convertToInt(s1) * 10000) / Math.pow(10, times);
      let ns = Math.floor(result) / 10000;
      return ns;
    } else {
      return 0;
    }
  },
  // js账号中间部分替换成星号
  formatName(account) {
    return account.substr(0, 3) + "*******" + account.substring(10, 14);
  },
  //加法
  accAdd(num1, num2) {
    num1 = Number(num1);
    num2 = Number(num2);
    var dec1, dec2, times;
    try {
      dec1 = countDecimals(num1) + 1;
    } catch (e) {
      dec1 = 0;
    }
    try {
      dec2 = countDecimals(num2) + 1;
    } catch (e) {
      dec2 = 0;
    }
    times = Math.pow(10, Math.max(dec1, dec2));
    var result = (this.accMul(num1, times) + this.accMul(num2, times)) / times;
    return getCorrectResult("add", num1, num2, result);
  },
  //减法
  accSub(num1, num2) {
    num1 = Number(num1);
    num2 = Number(num2);
    var dec1, dec2, times;
    try {
      dec1 = countDecimals(num1) + 1;
    } catch (e) {
      dec1 = 0;
    }
    try {
      dec2 = countDecimals(num2) + 1;
    } catch (e) {
      dec2 = 0;
    }
    times = Math.pow(10, Math.max(dec1, dec2));
    var result = Number(
      (this.accMul(num1, times) - this.accMul(num2, times)) / times
    );
    return getCorrectResult("sub", num1, num2, result);
  },
  //除法
  accDiv(num1, num2) {
    num1 = Number(num1);
    num2 = Number(num2);
    var t1 = 0,
      t2 = 0,
      dec1,
      dec2;
    try {
      t1 = countDecimals(num1);
    } catch (e) {}
    try {
      t2 = countDecimals(num2);
    } catch (e) {}
    dec1 = convertToInt(num1);
    dec2 = convertToInt(num2);
    var result = this.accMul(dec1 / dec2, Math.pow(10, t2 - t1));
    return getCorrectResult("div", num1, num2, result);
  },
  //乘法
  accMul(num1, num2) {
    num1 = Number(num1);
    num2 = Number(num2);
    var times = 0,
      s1 = num1.toString(),
      s2 = num2.toString();
    try {
      times += countDecimals(s1);
    } catch (e) {}
    try {
      times += countDecimals(s2);
    } catch (e) {}
    var result = (convertToInt(s1) * convertToInt(s2)) / Math.pow(10, times);
    return getCorrectResult("mul", num1, num2, result);
  },
  // getPlatform1() { // 判断app环境
  //   let p = navigator.platform;
  //   let u = navigator.userAgent;
  //   let is_android = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
  //   let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  //   if (
  //       p == "Win32" ||
  //       p == "Win64" ||
  //       p == "MacPPC" ||
  //       p == "MacIntel" ||
  //       p == "X11" ||
  //       p == "Linux i686" ||
  //       p == "Linux aarch64"
  //   ) {
  //     //PC调试环境
  //     return "PC";
  //   } else {
  //     if (is_android) {
  //       //Android终端
  //       return "ANDROID";
  //     } else if (is_ios) {
  //       //IOS终端
  //       return "IOS";
  //     }
  //   }
  // },
  getPlatform() {
    // 判断app环境
    let p = navigator.platform;
    let u = navigator.userAgent;
    let is_android = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
    let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (is_android) {
      //Android终端
      return "ANDROID";
    } else if (is_ios) {
      //IOS终端
      return "IOS";
    } else {
      return "pc";
    }
  },
  // 回调给app端的消息
  callBackApp(jsonData) {
    let os = this.getPlatform();
    localStorage.setItem("environmentVal", "PC");
    if ("ANDROID" == os) {
      if (window.Android && window.Android.callApp) {
        localStorage.setItem("environmentVal", "ANDROID");
        window.Android.callApp(JSON.stringify(jsonData));
      }
    } else if ("IOS" == os) {
      //调用oc方法
      if (window.webkit && window.webkit.messageHandlers) {
        localStorage.setItem("environmentVal", "IOS");
        window.webkit.messageHandlers.callApp.postMessage(
          JSON.stringify(jsonData)
        );
      }
    }
  },
  sign(obj) {
    let apiSecret = "";
    if (false) {
    } else {
      apiSecret = "ssDv2swc0b3zCPXi";
    }
    var keys = [];
    for (let key in obj) {
      keys.push(key);
    }
    keys.sort();
    let strTemp = "";
    for (let k in keys) {
      let value = obj[keys[k]];
      if ("string" != typeof value) {
        value = JSON.stringify(value);
      }
      strTemp += keys[k] + "=" + value + "&";
    }
    strTemp = strTemp.replace(/&$/, ""); //去掉最后的逗号
    const cryptedString = CryptoJS.HmacSHA256(strTemp, apiSecret).toString();
    return cryptedString;
  },
  GetRandomNum(min, max) {
    var range = max - min;
    var rand = Math.random();
    return min + Math.round(rand * range);
  },
};

