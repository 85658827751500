

var _default = {
  name: "Nome",
  tel: "Telefone",
  save: "Salvar",
  confirm: "Confirmar",
  cancel: "Cancelar",
  delete: "Excluir",
  complete: "Completo",
  loading: "Carregando...",
  telEmpty: "Por favor, preencha o telefone",
  nameEmpty: "Por favor, preencha o nome",
  nameInvalid: "Nome inválido",
  confirmDelete: "Tem certeza de que deseja excluir?",
  telInvalid: "Número de telefone inválido",
  vanCalendar: {
    end: "Fim",
    start: "Início",
    title: "Calendário",
    startEnd: "Início/Fim",
    weekdays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    monthTitle: function monthTitle(year, month) {
      return year + "/" + month;
    },
    rangePrompt: function rangePrompt(maxRange) {
      return "Escolha no máximo " + maxRange + " dias";
    }
  },
  vanCascader: {
    select: "Selecionar"
  },
  vanContactCard: {
    addText: "Adicionar informações de contato"
  },
  vanContactList: {
    addText: "Adicionar novo contato"
  },
  vanPagination: {
    prev: "Anterior",
    next: "Próximo"
  },
  vanPullRefresh: {
    pulling: "Puxe para atualizar...",
    loosing: "Solte para atualizar..."
  },
  vanSubmitBar: {
    label: "Total: "
  },
  vanCoupon: {
    unlimited: "Ilimitado",
    discount: function discount(_discount) {
      return _discount * 10 + "% de desconto";
    },
    condition: function condition(_condition) {
      return "Pelo menos " + _condition;
    }
  },
  vanCouponCell: {
    title: "Cupom",
    tips: "Sem cupons",
    count: function count(_count) {
      return "Você tem " + _count + " cupons";
    }
  },
  vanCouponList: {
    empty: "Sem cupons",
    exchange: "Trocar",
    close: "Fechar",
    enable: "Disponível",
    disabled: "Indisponível",
    placeholder: "Código do cupom"
  },
  vanAddressEdit: {
    area: "Área",
    postal: "CEP",
    areaEmpty: "Por favor, selecione uma área de entrega",
    addressEmpty: "O endereço não pode ficar vazio",
    postalEmpty: "CEP incorreto",
    defaultAddress: "Definir como endereço padrão",
    telPlaceholder: "Telefone",
    namePlaceholder: "Nome",
    areaPlaceholder: "Área"
  },
  vanAddressEditDetail: {
    label: "Endereço",
    placeholder: "Endereço"
  },
  vanAddressList: {
    add: "Adicionar novo endereço"
  }
};
export default _default

//# sourceURL=webpack:///./src/public/lang/ptPT.js?