

var _default = {
  name: "Име",
  tel: "Телефон",
  save: "Зачувај",
  confirm: "Потврди",
  cancel: "Откажи",
  delete: "Избриши",
  complete: "Заврши",
  loading: "Вчитување...",
  telEmpty: "Ве молиме внесете го телефонскиот број",
  nameEmpty: "Ве молиме внесете го името",
  nameInvalid: "Невалидно име",
  confirmDelete: "Дали сте сигурни дека сакате да го избришете?",
  telInvalid: "Невалиден телефонски број",
  vanCalendar: {
    end: "Крај",
    start: "Почеток",
    title: "Календар",
    startEnd: "Почеток/Крај",
    weekdays: ["Нед", "Пон", "Вто", "Сре", "Чет", "Пет", "Саб"],
    monthTitle: function monthTitle(year, month) {
      return year + "/" + month;
    },
    rangePrompt: function rangePrompt(maxRange) {
      return "Изберете не повеќе од " + maxRange + " денови";
    }
  },
  vanCascader: {
    select: "Избери"
  },
  vanContactCard: {
    addText: "Додади контакт информации"
  },
  vanContactList: {
    addText: "Додади нов контакт"
  },
  vanPagination: {
    prev: "Претходно",
    next: "Следно"
  },
  vanPullRefresh: {
    pulling: "Извлечи за освежување...",
    loosing: "Пушти за освежување..."
  },
  vanSubmitBar: {
    label: "Вкупно: "
  },
  vanCoupon: {
    unlimited: "Неограничено",
    discount: function discount(_discount) {
      return _discount * 10 + "% попуст";
    },
    condition: function condition(_condition) {
      return "Барем " + _condition;
    }
  },
  vanCouponCell: {
    title: "Купон",
    tips: "Нема купони",
    count: function count(_count) {
      return "Имате " + _count + " купони";
    }
  },
  vanCouponList: {
    empty: "Нема купони",
    exchange: "Замени",
    close: "Затвори",
    enable: "Достапно",
    disabled: "Недостапно",
    placeholder: "Код на купонот"
  },
  vanAddressEdit: {
    area: "Регион",
    postal: "Поштенски код",
    areaEmpty: "Ве молиме изберете регион за примање",
    addressEmpty: "Адресата не може да биде празна",
    postalEmpty: "Невалиден поштенски код",
    defaultAddress: "Постави како зададена адреса",
    telPlaceholder: "Телефон",
    namePlaceholder: "Име",
    areaPlaceholder: "Регион"
  },
  vanAddressEditDetail: {
    label: "Адреса",
    placeholder: "Адреса"
  },
  vanAddressList: {
    add: "Додади нова адреса"
  }
};
export default _default

//# sourceURL=webpack:///./src/public/lang/kkKZ.js?