
var _default = {
  name: "نام",
  tel: "تلفن",
  save: "ذخیره",
  confirm: "تأیید",
  cancel: "لغو",
  delete: "حذف",
  complete: "تکمیل",
  loading: "بارگیری...",
  telEmpty: "لطفاً تلفن را پر کنید",
  nameEmpty: "لطفاً نام را پر کنید",
  nameInvalid: "نام نامعتبر",
  confirmDelete: "آیا از حذف اطمینان دارید؟",
  telInvalid: "شماره تلفن نامعتبر",
  vanCalendar: {
    end: "پایان",
    start: "شروع",
    title: "تقویم",
    startEnd: "شروع/پایان",
    weekdays: ["یک‌شنبه", "دو‌شنبه", "سه‌شنبه", "چهار‌شنبه", "پنج‌شنبه", "جمعه", "شنبه"],
    monthTitle: function monthTitle(year, month) {
      return year + "/" + month;
    },
    rangePrompt: function rangePrompt(maxRange) {
      return "حداکثر " + maxRange + " روز را انتخاب کنید";
    }
  },
  vanCascader: {
    select: "انتخاب"
  },
  vanContactCard: {
    addText: "اضافه کردن اطلاعات تماس"
  },
  vanContactList: {
    addText: "اضافه کردن مخاطب جدید"
  },
  vanPagination: {
    prev: "قبلی",
    next: "بعدی"
  },
  vanPullRefresh: {
    pulling: "کشیدن برای رفرش...",
    loosing: "رها کردن برای رفرش..."
  },
  vanSubmitBar: {
    label: "مجموع: "
  },
  vanCoupon: {
    unlimited: "نامحدود",
    discount: function discount(_discount) {
      return _discount * 10 + "% تخفیف";
    },
    condition: function condition(_condition) {
      return "حداقل " + _condition;
    }
  },
  vanCouponCell: {
    title: "کوپن",
    tips: "بدون کوپن",
    count: function count(_count) {
      return "شما " + _count + " کوپن دارید";
    }
  },
  vanCouponList: {
    empty: "بدون کوپن",
    exchange: "تبادل",
    close: "بستن",
    enable: "در دسترس",
    disabled: "غیرفعال",
    placeholder: "کد کوپن"
  },
  vanAddressEdit: {
    area: "منطقه",
    postal: "کد پستی",
    areaEmpty: "لطفاً یک منطقه دریافت را انتخاب کنید",
    addressEmpty: "آدرس نمی‌تواند خالی باشد",
    postalEmpty: "کد پستی اشتباه است",
    defaultAddress: "تنظیم به عنوان آدرس پیشفرض",
    telPlaceholder: "تلفن",
    namePlaceholder: "نام",
    areaPlaceholder: "منطقه"
  },
  vanAddressEditDetail: {
    label: "آدرس",
    placeholder: "آدرس"
  },
  vanAddressList: {
    add: "اضافه کردن آدرس جدید"
  }
};
export default _default
