

var _default = {
  name: "নাম",
  tel: "ফোন",
  save: "সংরক্ষণ",
  confirm: "নিশ্চিত করুন",
  cancel: "বাতিল",
  delete: "মুছে ফেলুন",
  complete: "সম্পন্ন",
  loading: "লোড হচ্ছে...",
  telEmpty: "দয়া করে ফোন নম্বর পূরণ করুন",
  nameEmpty: "দয়া করে নাম পূরণ করুন",
  nameInvalid: "অযোগ্য নাম",
  confirmDelete: "আপনি কি নিশ্চিত যে আপনি এটি মুছতে চাচ্ছেন?",
  telInvalid: "অযোগ্য ফোন নম্বর",
  vanCalendar: {
    end: "শেষ",
    start: "শুরু",
    title: "ক্যালেন্ডার",
    startEnd: "শুরু/শেষ",
    weekdays: ["রবি", "সোম", "মঙ্গল", "বুধ", "বৃহস্পতি", "শুক্র", "শনি"],
    monthTitle: function monthTitle(year, month) {
      return year + "/" + month;
    },
    rangePrompt: function rangePrompt(maxRange) {
      return "সর্বাধিক " + maxRange + " দিন নির্বাচন করুন";
    }
  },
  vanCascader: {
    select: "নির্বাচন করুন"
  },
  vanContactCard: {
    addText: "যোগ করুন যোগাযোগ তথ্য"
  },
  vanContactList: {
    addText: "নতুন যোগাযোগ যোগ করুন"
  },
  vanPagination: {
    prev: "পূর্ববর্তী",
    next: "পরবর্তী"
  },
  vanPullRefresh: {
    pulling: "তাজা করতে টানুন...",
    loosing: "তাজা করতে আবহাওয়া পানি দিন..."
  },
  vanSubmitBar: {
    label: "মোট: "
  },
  vanCoupon: {
    unlimited: "সীমাহীন",
    discount: function discount(_discount) {
      return _discount * 10 + "% ছাড়";
    },
    condition: function condition(_condition) {
      return "কমপক্ষে " + _condition;
    }
  },
  vanCouponCell: {
    title: "কুপন",
    tips: "কোন কুপন নেই",
    count: function count(_count) {
      return "আপনার কাছে " + _count + " টি কুপন আছে";
    }
  },
  vanCouponList: {
    empty: "কোন কুপন নেই",
    exchange: "বিনিময় করুন",
    close: "বন্ধ করুন",
    enable: "উপলব্ধ",
    disabled: "অপ্রাপ্য",
    placeholder: "কুপন কোড"
  },
  vanAddressEdit: {
    area: "এলাকা",
    postal: "পোস্টাল",
    areaEmpty: "দয়া করে একটি প্রাপ্তি এলাকা নির্বাচন করুন",
    addressEmpty: "ঠিকানা খালি রাখা যাবে না",
    postalEmpty: "ভুল পোস্টাল কোড",
    defaultAddress: "ডিফল্ট ঠিকানা হিসেবে সেট করুন",
    telPlaceholder: "ফোন",
    namePlaceholder: "নাম",
    areaPlaceholder: "এলাকা"
  },
  vanAddressEditDetail: {
    label: "ঠিকানা",
    placeholder: "ঠিকানা"
  },
  vanAddressList: {
    add: "নতুন ঠিকানা যোগ করুন"
  }
};
export default _default
