<template lang="">
  <div
    id="app"
    :class="{applang: lang!== 'zh_TW'}"
  >
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <div
      v-show="listTop"
      id="Top"
      class="Top"
      @click="ClickTop()"
    >
      <img src="./assets/images/i_top.png">
    </div>
    <loading v-show="showloading" />
  </div>
</template>

<script>
import store from "@/store";
import http from "@/utils/http";
import { mapMutations } from "vuex";

export default {
  name: "App",
  data() {
    return {
      listTop: false,
      showloading: false,
      channelList: [],
      lang: this.$store.state.lang,
      times: 3,
      timer: null,
      info: [],
    };
  },
  watch: {
    $route(to, from) {
      if (this.$route.meta.title != "") {
        this.lang = this.$store.state.lang;
        document.title = this.$t(this.$route.meta.title);
      }
    },
  },
  created() {
    if (localStorage.getItem("language") != undefined) {
      this.$i18n.locale = localStorage.getItem("language");
    } else {
      //默认中文
      this.$i18n.locale = "en_US";
    }
    var _this = this;
    _this.showloading = false;
    http.bus.$on("showloading", function () {
      _this.showloading = true;
    });
    http.bus.$on("closeLoading", function () {
      _this.showloading = false;
    });
    this.getFinanceIsOpen();
  },
  mounted() {
    this.$post(this.URL.user.urlGroup, {
      groupKey: "CONTACT",
    }).then((res) => {
      if (res.code == 0) {
        this.info = res.data;
      } else if (res.code !== 90001) {
        this.Toast(res.message);
      }
    });

    // document.title = this.$t(this.$route.meta.title);
    this.timer = setInterval(() => {
      this.times--;
      if (this.times === 0) {
        clearInterval(this.timer);
      }
    }, 1000);
    window.addEventListener("scroll", this.handleScroll, true);
  },
  beforeCreate() {
    document.cookie = "Is_notice=1";
  },
  methods: {
    ...mapMutations(["SET_ISAPPINFO"]),
    getFinanceIsOpen() {
      this.$post(this.URL.income.isOpen, {}).then((res) => {
        if (res.code == 0) {
          store.commit("SET_FINANCE_IS_OPEN", res.data);
        }
      });
    },
    path_url(url) {
      this.$router.push({
        path: url,
        query: {},
      });
    },
    ClickTop() {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    handleScroll() {
      let t = document.documentElement.scrollTop || document.body.scrollTop;
      if (t > 2000) {
        this.listTop = true;
      } else {
        this.listTop = false;
      }
    },
  },
  beforeEach(to, from, next) {},
};
</script>
<style scoped>
</style>
