import Vue from "vue";
import moment from "moment";
import md5 from "js-md5";
import qs from "qs";
/*! @vant/touch-emulator */
import '@vant/touch-emulator'
import VueSocialSharing from "vue-social-sharing";
import {
  Calendar,
  Cell,
  CellGroup,
  Checkbox,
  CheckboxGroup,
  CountDown,
  DatetimePicker,
  Dialog,
  Empty,
  Icon,
  Image,
  ImagePreview,
  Lazyload,
  List,
  NumberKeyboard,
  PasswordInput,
  Picker,
  Popup,
  PullRefresh,
  Radio,
  RadioGroup,
  Stepper,
  Sticky,
  Swipe,
  SwipeItem,
  Tab,
  Tabs,
  Toast,
  Uploader,
  NoticeBar
} from "vant";
import "vant/lib/index.css";
import "@/public/css/common.css";
import "@/utils/meiqia";
import "@/utils/libs/gt";
import * as echarts from "echarts";
import vue_clipboard2 from "vue-clipboard2";
// import Lottie from "vue-lottie";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import Loading from "./components/loading.vue";
import preventRepeatClick from "./utils/preventRepeatClick";
import glComponents from "./components/glComponents";
import locales from "@/i18n/languages/vantLocal";
import AesUtil from "./utils/AesUtil";
import common from "./utils/common";
import directive from "./utils/directive";
import url from "./utils/url";
import http from "./utils/http";

import axios from "axios";

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

Vue.prototype.$aes = AesUtil;

Vue.use(Toast)
  .use(Loading)
  .use(Image)
  .use(Swipe)
  .use(SwipeItem)
  .use(Popup)
  .use(Icon)
  .use(Uploader)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Empty)
  .use(Calendar)
  .use(Tabs)
  .use(Picker)
  .use(PasswordInput)
  .use(NumberKeyboard)
  .use(CountDown)
  .use(Tab)
  .use(RadioGroup)
  .use(Radio)
  .use(NoticeBar)
  .use(Stepper)
  .use(CellGroup)
  .use(PullRefresh)
  .use(List)
  .use(Cell)
  .use(Dialog)
  .use(CountDown)
  .use(DatetimePicker)
  .use(Sticky)
  .use(Lazyload, {
    preLoad: 1.3,
    error: "../static/images/error.png",
    loading: "../static/images/error.png",
    attempt: 1,
  });
Vue.prototype.Dialog = Dialog;
Vue.prototype.Toast = Toast;
Vue.prototype.ImagePreview = ImagePreview;

// 引入md5

Vue.prototype.$md5 = md5;
// 引入API列表

Vue.prototype.URL = url;

// 公共js

Vue.prototype.common = common;

Vue.use(preventRepeatClick);

Vue.use(VueSocialSharing);

//定义全局变量
Vue.prototype.$post = http.post;
Vue.prototype.$get = http.get;

Vue.use(glComponents);

Vue.use(vue_clipboard2);

Vue.prototype.$echarts = echarts;

//时间转化

Vue.prototype.$moment = moment;

//将次方法暴露给Vue原型上
Vue.prototype.$Local = locales;
//设置默认语言
let z =
  localStorage.getItem("language") == null
    ? "en_US"
    : localStorage.getItem("language");
locales(z);

// Vue.component("lottie", Lottie);

Vue.use(directive);

// post请求是格式化数据
Vue.prototype.$post = http.post;
Vue.prototype.$get = http.get;

Vue.prototype.$qs = qs;

new Vue({
  router: router,
  i18n: i18n,
  store: store,
  render: (h) => h(App),
}).$mount("#app");
