export default {
  install(Vue) {
    Vue.directive("no-space", {
      bind(el) {
        el.addEventListener("input", () => {
          // 检查输入框的值是否发生变化
          const originalValue = el.value;
          const newValue = originalValue.replace(/\s/g, "");

          // 只在值发生变化时执行替换操作
          if (originalValue !== newValue) {
            el.value = newValue;
            el.dispatchEvent(new Event("input"));
          }
        });
      },
    });
    Vue.directive("number", {
      bind(el) {
        el.addEventListener("input", () => {
          // 检查输入框的值是否发生变化
          const originalValue = el.value;
          // 使用正则表达式替换非数字字符和空格
          const newValue = originalValue.replace(/[^0-9]/g, "");

          // 只在值发生变化时执行替换操作
          if (originalValue !== newValue) {
            el.value = newValue;
            el.dispatchEvent(new Event("input"));
          }
        });
      },
    });
  },
};

