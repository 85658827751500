import {Locale} from "vant";

import * as zh_CN from "vant/lib/locale/lang/zh-CN";


import * as en_US from "vant/lib/locale/lang/en-US";

import * as zh_TW from "vant/lib/locale/lang/zh-TW";

import * as zh_HK from "vant/lib/locale/lang/zh-HK";

import * as de_DE from "vant/lib/locale/lang/de-DE";

import * as es_ES from "vant/lib/locale/lang/es-ES";

import * as tr_TR from "vant/lib/locale/lang/tr-TR";

import * as ro_RO from "vant/lib/locale/lang/ro-RO";


import * as fr_FR from "@/public/lang/frFR";

import * as ar_AE from "@/public/lang/arAE";

import * as ko_KR from "@/public/lang/koKR";

import * as it_IT from "@/public/lang/itIT";

import * as pt_PT from "@/public/lang/ptPT";

import * as kk_KZ from "@/public/lang/kkKZ";

import * as fa_IR from "@/public/lang/faIR";

import * as hi_IN from "@/public/lang/hiIN";

import * as ben_BD from "@/public/lang/benBD";

import * as hu_HU from "@/public/lang/huHU";

import * as bg_BG from "@/public/lang/bgBG";

import * as sr_RS from "@/public/lang/srRS";

function locales(a) {
  if (a == "bg_BG") {
    Locale.use("bg_BG", bg_BG);
  }
  if (a == "hu_HU") {
    Locale.use("hu_HU", hu_HU);
  }
  if (a == "zh_HK") {
    Locale.use("zh_HK", zh_HK);
  }
  if (a == "it_IT") {
    Locale.use("it_IT", it_IT);
  }
  if (a == "pt_PT") {
    Locale.use("pt_PT", pt_PT);
  }
  if (a == "tr_TR") {
    Locale.use("tr_TR", tr_TR);
  }
  if (a == "ro_RO") {
    Locale.use("ro_RO", ro_RO);
  }
  if (a == "es_ES") {
    Locale.use("es_ES", es_ES);
  }
  if (a == "ko_KR") {
    Locale.use("ko_KR", ko_KR);
  }
  if (a == "zh_CN") {
    Locale.use("zh_CN", zh_CN);
  }
  if (a == "ar_AE") {
    Locale.use("ar_AE", ar_AE);
  }
  if (a == "de_DE") {
    Locale.use("de_DE", de_DE);
  }
  if (a == "fr_FR") {
    Locale.use("fr_FR", fr_FR);
  }
  if (a == "ru_RU") {
    Locale.use("ru_RU", ru_RU);
  }
  if (a == "en_US") {
    Locale.use("en_US", en_US);
  }
  if (a == "zh_TW") {
    Locale.use("zh_TW", zh_TW);
  }
  if (a == "kk_KZ") {
    Locale.use("kk_KZ", kk_KZ);
  }
  if (a == "fa_IR") {
    Locale.use("fa_IR", fa_IR);
  }
  if (a == "hi_IN") {
    Locale.use("hi_IN", hi_IN);
  }
  if (a == "ben_BD") {
    Locale.use("ben_BD", ben_BD);
  }
  if (a == "sr_RS") {
    Locale.use("sr_RS", sr_RS);
  }
}

export default locales;
