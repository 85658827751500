

var _default = {
  name: "이름",
  tel: "전화번호",
  save: "저장",
  confirm: "확인",
  cancel: "취소",
  delete: "삭제",
  complete: "완료",
  loading: "로딩 중...",
  telEmpty: "전화번호를 입력해주세요",
  nameEmpty: "이름을 입력해주세요",
  nameInvalid: "잘못된 이름",
  confirmDelete: "정말 삭제하시겠습니까?",
  telInvalid: "잘못된 전화번호",
  vanCalendar: {
    end: "종료",
    start: "시작",
    title: "달력",
    startEnd: "시작/종료",
    weekdays: ["일", "월", "화", "수", "목", "금", "토"],
    monthTitle: function monthTitle(year, month) {
      return year + "/" + month;
    },
    rangePrompt: function rangePrompt(maxRange) {
      return maxRange + "일 이내로 선택해주세요";
    }
  },
  vanCascader: {
    select: "선택"
  },
  vanContactCard: {
    addText: "연락처 정보 추가"
  },
  vanContactList: {
    addText: "새 연락처 추가"
  },
  vanPagination: {
    prev: "이전",
    next: "다음"
  },
  vanPullRefresh: {
    pulling: "당겨서 새로고침...",
    loosing: "놓아서 새로고침..."
  },
  vanSubmitBar: {
    label: "총액: "
  },
  vanCoupon: {
    unlimited: "무제한",
    discount: function discount(_discount) {
      return _discount * 10 + "% 할인";
    },
    condition: function condition(_condition) {
      return _condition + " 이상 구매 시";
    }
  },
  vanCouponCell: {
    title: "쿠폰",
    tips: "쿠폰 없음",
    count: function count(_count) {
      return _count + "개의 쿠폰 보유 중";
    }
  },
  vanCouponList: {
    empty: "쿠폰 없음",
    exchange: "교환",
    close: "닫기",
    enable: "사용 가능",
    disabled: "사용 불가",
    placeholder: "쿠폰 코드"
  },
  vanAddressEdit: {
    area: "지역",
    postal: "우편번호",
    areaEmpty: "수령 지역을 선택해주세요",
    addressEmpty: "주소를 입력해주세요",
    postalEmpty: "잘못된 우편번호",
    defaultAddress: "기본 주소로 설정",
    telPlaceholder: "전화번호",
    namePlaceholder: "이름",
    areaPlaceholder: "지역"
  },
  vanAddressEditDetail: {
    label: "상세 주소",
    placeholder: "주소를 입력하세요"
  },
  vanAddressList: {
    add: "새 주소 추가"
  }
};
export default _default

//# sourceURL=webpack:///./src/public/lang/koKR.js?