

var _default = {
  name: "नाम",
  tel: "फ़ोन",
  save: "सहेजें",
  confirm: "पुष्टि करें",
  cancel: "रद्द करें",
  delete: "हटाएं",
  complete: "पूर्ण",
  loading: "लोड हो रहा है...",
  telEmpty: "कृपया फ़ोन नंबर भरें",
  nameEmpty: "कृपया नाम भरें",
  nameInvalid: "अशुद्ध नाम",
  confirmDelete: "क्या आप सुनिश्चित हैं कि आप हटाना चाहते हैं?",
  telInvalid: "अशुद्ध फ़ोन नंबर",
  vanCalendar: {
    end: "समाप्त",
    start: "प्रारंभ",
    title: "कैलेंडर",
    startEnd: "प्रारंभ/समाप्त",
    weekdays: ["रवि", "सोम", "मंगल", "बुध", "गुरु", "शुक्र", "शनि"],
    monthTitle: function monthTitle(year, month) {
      return year + "/" + month;
    },
    rangePrompt: function rangePrompt(maxRange) {
      return "अधिकतम " + maxRange + " दिन चुनें";
    }
  },
  vanCascader: {
    select: "चयन करें"
  },
  vanContactCard: {
    addText: "संपर्क जानकारी जोड़ें"
  },
  vanContactList: {
    addText: "नया संपर्क जोड़ें"
  },
  vanPagination: {
    prev: "पिछला",
    next: "अगला"
  },
  vanPullRefresh: {
    pulling: "ताजगी के लिए खींचें...",
    loosing: "ताजगी के लिए छोड़ें..."
  },
  vanSubmitBar: {
    label: "कुल: "
  },
  vanCoupon: {
    unlimited: "असीमित",
    discount: function discount(_discount) {
      return _discount * 10 + "% छूट";
    },
    condition: function condition(_condition) {
      return "कम से कम " + _condition;
    }
  },
  vanCouponCell: {
    title: "कूपन",
    tips: "कोई कूपन नहीं",
    count: function count(_count) {
      return "आपके पास " + _count + " कूपन हैं";
    }
  },
  vanCouponList: {
    empty: "कोई कूपन नहीं",
    exchange: "विनिमय करें",
    close: "बंद करें",
    enable: "उपलब्ध",
    disabled: "अक्षम",
    placeholder: "कूपन कोड"
  },
  vanAddressEdit: {
    area: "क्षेत्र",
    postal: "पोस्टल",
    areaEmpty: "कृपया प्राप्ति क्षेत्र का चयन करें",
    addressEmpty: "पता खाली नहीं हो सकता",
    postalEmpty: "गलत पोस्टल कोड",
    defaultAddress: "डिफ़ॉल्ट पता के रूप में सेट करें",
    telPlaceholder: "फ़ोन",
    namePlaceholder: "नाम",
    areaPlaceholder: "क्षेत्र"
  },
  vanAddressEditDetail: {
    label: "पता",
    placeholder: "पता"
  },
  vanAddressList: {
    add: "नया पता जोड़ें"
  }
};
export default _default

//# sourceURL=webpack:///./src/public/lang/hiIN.js?