

var _default = {
  name: "Név",
  tel: "Telefonszám",
  save: "Mentés",
  confirm: "Megerősítés",
  cancel: "Mégse",
  delete: "Törlés",
  complete: "Befejezve",
  loading: "Betöltés...",
  telEmpty: "Kérjük, töltse ki a telefonszámot",
  nameEmpty: "Kérjük, töltse ki a nevet",
  nameInvalid: "Érvénytelen név",
  confirmDelete: "Biztosan törölni szeretné?",
  telInvalid: "Érvénytelen telefonszám",
  vanCalendar: {
    end: "Vége",
    start: "Kezdete",
    title: "Naptár",
    startEnd: "Kezdés/Vége",
    weekdays: ["Vas", "Hét", "Ked", "Sze", "Csü", "Pén", "Szo"],
    monthTitle: function monthTitle(year, month) {
      return year + "/" + month;
    },
    rangePrompt: function rangePrompt(maxRange) {
      return "Válasszon legfeljebb " + maxRange + " napot";
    }
  },
  vanCascader: {
    select: "Válasszon"
  },
  vanContactCard: {
    addText: "Adjon hozzá kapcsolati információkat"
  },
  vanContactList: {
    addText: "Új kapcsolat hozzáadása"
  },
  vanPagination: {
    prev: "Előző",
    next: "Következő"
  },
  vanPullRefresh: {
    pulling: "Húzza le a frissítéshez...",
    loosing: "Engedje el a frissítéshez..."
  },
  vanSubmitBar: {
    label: "Összesen: "
  },
  vanCoupon: {
    unlimited: "Korlátlan",
    discount: function discount(_discount) {
      return _discount * 10 + "% kedvezmény";
    },
    condition: function condition(_condition) {
      return "Legalább " + _condition;
    }
  },
  vanCouponCell: {
    title: "Kupon",
    tips: "Nincs kupon",
    count: function count(_count) {
      return "Önnek " + _count + " kuponja van";
    }
  },
  vanCouponList: {
    empty: "Nincs kupon",
    exchange: "Csere",
    close: "Bezárás",
    enable: "Elérhető",
    disabled: "Nem elérhető",
    placeholder: "Kupon kód"
  },
  vanAddressEdit: {
    area: "Terület",
    postal: "Irányítószám",
    areaEmpty: "Kérjük, válasszon egy kiszállítási területet",
    addressEmpty: "Az cím nem lehet üres",
    postalEmpty: "Helytelen irányítószám",
    defaultAddress: "Állítsa be alapértelmezett címként",
    telPlaceholder: "Telefonszám",
    namePlaceholder: "Név",
    areaPlaceholder: "Terület"
  },
  vanAddressEditDetail: {
    label: "Cím",
    placeholder: "Cím"
  },
  vanAddressList: {
    add: "Új cím hozzáadása"
  }
};
export default _default

//# sourceURL=webpack:///./src/public/lang/huHU.js?