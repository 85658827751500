

var _default = {
  name: "Nom",
  tel: "Téléphone",
  save: "Enregistrer",
  confirm: "Confirmer",
  cancel: "Annuler",
  delete: "Supprimer",
  complete: "Complet",
  loading: "Chargement...",
  telEmpty: "Veuillez remplir le numéro de téléphone",
  nameEmpty: "Veuillez remplir le nom",
  nameInvalid: "Nom malformé",
  confirmDelete: "Êtes-vous sûr de vouloir supprimer ?",
  telInvalid: "Numéro de téléphone malformé",
  vanCalendar: {
    end: "Fin",
    start: "Début",
    title: "Calendrier",
    startEnd: "Début/Fin",
    weekdays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    monthTitle: function monthTitle(year, month) {
      return year + "/" + month;
    },
    rangePrompt: function rangePrompt(maxRange) {
      return "Choisissez au maximum " + maxRange + " jours";
    }
  },
  vanCascader: {
    select: "Sélectionner"
  },
  vanContactCard: {
    addText: "Ajouter des informations de contact"
  },
  vanContactList: {
    addText: "Ajouter un nouveau contact"
  },
  vanPagination: {
    prev: "Précédent",
    next: "Suivant"
  },
  vanPullRefresh: {
    pulling: "Tirer pour rafraîchir...",
    loosing: "Relâcher pour rafraîchir..."
  },
  vanSubmitBar: {
    label: "Total : "
  },
  vanCoupon: {
    unlimited: "Illimité",
    discount: function discount(_discount) {
      return _discount * 10 + "% de réduction";
    },
    condition: function condition(_condition) {
      return "Au moins " + _condition;
    }
  },
  vanCouponCell: {
    title: "Coupon",
    tips: "Pas de coupons",
    count: function count(_count) {
      return "Vous avez " + _count + " coupons";
    }
  },
  vanCouponList: {
    empty: "Pas de coupons",
    exchange: "Échanger",
    close: "Fermer",
    enable: "Disponible",
    disabled: "Indisponible",
    placeholder: "Code du coupon"
  },
  vanAddressEdit: {
    area: "Zone",
    postal: "Code postal",
    areaEmpty: "Veuillez sélectionner une zone de réception",
    addressEmpty: "L'adresse ne peut pas être vide",
    postalEmpty: "Code postal incorrect",
    defaultAddress: "Définir comme adresse par défaut",
    telPlaceholder: "Téléphone",
    namePlaceholder: "Nom",
    areaPlaceholder: "Zone"
  },
  vanAddressEditDetail: {
    label: "Adresse",
    placeholder: "Adresse"
  },
  vanAddressList: {
    add: "Ajouter une nouvelle adresse"
  }
};
export default _default

//# sourceURL=webpack:///./src/public/lang/frFR.js?