
var _default = {
  name: "الاسم",
  tel: "الهاتف",
  save: "حفظ",
  confirm: "تأكيد",
  cancel: "إلغاء",
  delete: "حذف",
  complete: "اكتمال",
  loading: "جار التحميل...",
  telEmpty: "يرجى ملء رقم الهاتف",
  nameEmpty: "يرجى ملء الاسم",
  nameInvalid: "اسم غير صحيح",
  confirmDelete: "هل أنت متأكد أنك تريد الحذف؟",
  telInvalid: "رقم هاتف غير صحيح",
  vanCalendar: {
    end: "النهاية",
    start: "البداية",
    title: "التقويم",
    startEnd: "البداية/النهاية",
    weekdays: ["الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"],
    monthTitle: function monthTitle(year, month) {
      return year + "/" + month;
    },
    rangePrompt: function rangePrompt(maxRange) {
      return "اختر لا يزيد عن " + maxRange + " أيام";
    }
  },
  vanCascader: {
    select: "اختيار"
  },
  vanContactCard: {
    addText: "إضافة معلومات الاتصال"
  },
  vanContactList: {
    addText: "إضافة جهة اتصال جديدة"
  },
  vanPagination: {
    prev: "السابق",
    next: "التالي"
  },
  vanPullRefresh: {
    pulling: "اسحب للتحديث...",
    loosing: "افلت للتحديث..."
  },
  vanSubmitBar: {
    label: "الإجمالي: "
  },
  vanCoupon: {
    unlimited: "غير محدود",
    discount: function discount(_discount) {
      return _discount * 10 + "% خصم";
    },
    condition: function condition(_condition) {
      return "على الأقل " + _condition;
    }
  },
  vanCouponCell: {
    title: "كوبون",
    tips: "لا توجد كوبونات",
    count: function count(_count) {
      return "لديك " + _count + " كوبونات";
    }
  },
  vanCouponList: {
    empty: "لا توجد كوبونات",
    exchange: "تبديل",
    close: "إغلاق",
    enable: "متاح",
    disabled: "غير متاح",
    placeholder: "رمز الكوبون"
  },
  vanAddressEdit: {
    area: "المنطقة",
    postal: "الرمز البريدي",
    areaEmpty: "يرجى اختيار منطقة الاستلام",
    addressEmpty: "لا يمكن ترك العنوان فارغًا",
    postalEmpty: "رمز بريدي خاطئ",
    defaultAddress: "تعيين كعنوان افتراضي",
    telPlaceholder: "الهاتف",
    namePlaceholder: "الاسم",
    areaPlaceholder: "المنطقة"
  },
  vanAddressEditDetail: {
    label: "العنوان",
    placeholder: "العنوان"
  },
  vanAddressList: {
    add: "إضافة عنوان جديد"
  }
};
export default _default
