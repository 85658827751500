import Vue from "vue";
import store from "../store";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
  routes: [
    {
      path: "/home",
      component: () => import("../views/home/index.vue"),
      meta: {
        title: "title.doc1",
      },
    },
    {
      path: "/",
      component: () => import("../views/home/index.vue"),
      meta: {
        title: "title.doc1",
      },
    },
    {
      path: "/user/login_reg",
      component: () => import("../views/user/login_reg.vue"),
      meta: {
        title: "page1.doc1",
      },
    },
    {
      path: "/user/login",
      component: () => import("../views/user/login.vue"),
      meta: {
        title: "title.doc2",
      },
    },
    {
      path: "/user/reg",
      component: () => import("../views/user/reg.vue"),
      meta: {
        title: "title.doc3",
      },
    },
    {
      path: "/user/forgot_password",
      component: () => import("../views/user/forgot_password.vue"),
      meta: {
        title: "title.doc4",
      },
    },
    {
      path: "/user/forgot_password_ok",
      component: () => import("../views/user/forgot_password_ok.vue"),
      meta: {
        title: "title.doc5",
      },
    },
    {
      path: "/my/index",
      component: () => import("../views/my/index.vue"),
      meta: {
        title: "title.doc6",
        auth: true,
      },
    },
    {
      path: "/my/wallet",
      component: () => import("../views/my/wallet.vue"),
      meta: {
        title: "title.doc7",
        auth: true,
      },
    },
    {
      path: "/wallet/iframe",
      component: () => import("../views/wallet/iframe.vue"),
      meta: {
        title: "page.doc124",
      },
    },
    {
      path: "/my/wallet_add",
      component: () => import("../views/my/wallet_add.vue"),
      meta: {
        title: "title.doc8",
        auth: true,
      },
    },
    {
      path: "/my/help",
      component: () => import("../views/my/help.vue"),
      meta: {
        title: "title.doc9",
        auth: true,
      },
    },
    {
      path: "/my/help_view",
      component: () => import("../views/my/help_view.vue"),
      meta: {
        title: "title.doc10",
        auth: true,
      },
    },
    {
      path: "/my/lang",
      component: () => import("../views/my/lang.vue"),
      meta: {
        title: "title.doc11",
      },
    },
    {
      path: "/my/reg_password",
      component: () => import("../views/my/reg_password.vue"),
      meta: {
        title: "title.doc12",
        auth: true,
      },
    },
    {
      path: "/my/fund_password",
      component: () => import("../views/my/fund_password.vue"),
      meta: {
        title: "page1.doc67",
        auth: true,
      },
    },
    {
      path: "/my/fund_password1",
      component: () => import("../views/my/fund_password1.vue"),
      meta: {
        title: "title.doc13",
      },
    },
    {
      path: "/my/grade",
      component: () => import("../views/my/grade.vue"),
      meta: {
        title: "title.doc14",
        auth: true,
      },
    },
    {
      path: "/wallet/index",
      component: () => import("../views/wallet/index.vue"),
      meta: {
        title: "title.doc15",
        auth: true,
      },
    },
    {
      path: "/wallet/recharge",
      component: () => import("../views/wallet/recharge.vue"),
      meta: {
        title: "title.doc16",
        auth: true,
      },
    },
    {
      path: "/wallet/recharge_h5",
      component: () => import("../views/wallet/recharge_h5.vue"),
      meta: {
        title: "title.doc6",
        auth: true,
        h5: true,
      },
    },
    {
      path: "/wallet/withdraw",
      component: () => import("../views/wallet/withdraw.vue"),
      meta: {
        title: "title.doc17",
        address: true,
        auth: true,
      },
    },
    {
      path: "/ranking",
      component: () => import("../views/ranking.vue"),
      meta: {
        title: "title.doc18",
        auth: true,
      },
    },
    {
      path: "/information/index",
      component: () => import("../views/information/index.vue"),
      meta: {
        title: "title.doc19",
        auth: true,
      },
    },
    {
      path: "/information/list",
      component: () => import("../views/information/list.vue"),
      meta: {
        title: "title.doc19",
      },
    },
    {
      path: "/information/view",
      component: () => import("../views/information/view.vue"),
      meta: {
        title: "title.doc20",
      },
    },
    {
      path: "/team/index",
      component: () => import("../views/team/index.vue"),
      meta: {
        title: "title.doc21",
        auth: true,
      },
    },
    {
      path: "/team/list",
      component: () => import("../views/team/list.vue"),
      meta: {
        title: "title.doc22",
      },
    },
    {
      path: "/team/data_list",
      component: () => import("../views/team/data_list.vue"),
      meta: {
        title: "title.doc22",
      },
    },
    {
      path: "/my/bind_email",
      component: () => import("../views/my/bind_email.vue"),
      meta: {
        title: "page1.doc66",
      },
    },
    {
      path: "/my/kf",
      component: () => import("../views/my/kf.vue"),
      meta: {
        title: "page.doc306",
      },
    },
    {
      path: "/finance/index",
      component: () => import("../views/finance/index.vue"),
      meta: {
        title: "page1.doc8",
      },
    },
    {
      path: "/finance/view",
      component: () => import("../views/finance/view.vue"),
      meta: {
        title: "page.doc308",
      },
    },
    {
      path: "/task/current_withdraw",
      component: () => import("../views/task/current_withdraw.vue"),
      meta: {
        title: "page.doc308",
      },
    },
    {
      path: "/task/index",
      component: () => import("../views/task/index.vue"),
      meta: {
        title: "page1.doc7",
      },
    },
    {
      path: "/event",
      component: () => import("../views/event.vue"),
      meta: {
        title: "page1.doc53",
      },
    },
    {
      path: "/order/record",
      component: () => import("../views/order/record.vue"),
      meta: {
        title: "page1.doc52",
        auth: true,
      },
      children: [
        {
          path: "",
          component: () => import("../views/task/shop.vue"),
          meta: {
            auth: true,
          },
        },
        {
          path: "shop",
          meta: {
            title: "page1.doc52",
          },
          component: () => import("../views/task/shop.vue"),
        },
        {
          path: "ht",
          meta: {
            title: "title.doc23",
          },
          component: () => import("../views/task/ht.vue"),
        },
      ],
    },
    {
      path: "/task/view",
      component: () => import("../views/task/view.vue"),
      meta: {
        title: "title.doc24",
        auth: true,
      },
    },
    {
      path: "/task/view_go",
      component: () => import("../views/task/view_go.vue"),
      meta: {
        title: "title.doc24",
        auth: true,
      },
    },
    {
      path: "/article",
      component: () => import("../views/article.vue"),
      meta: {
        title: "title.doc10",
      },
    },
    {
      path: "/invite",
      component: () => import("../views/invite.vue"),
      meta: {
        title: "title.doc25",
        auth: true,
      },
    },
    {
      path: "/my/feedback",
      component: () => import("../views/my/feedback.vue"),
      meta: {
        title: "title.doc26",
        auth: true,
      },
    },
    {
      path: "/my/bind_wallet_address",
      component: () => import("../views/my/bind_wallet_address.vue"),
      meta: {
        title: "page.doc287",
        auth: true,
      },
    },
    // {
    //   path: "/my/lucky",
    //   component: () => import("../views/my/lucky.vue"),
    //   meta: {
    //     title: "page2.doc309",
    //   },
    // },
    {
      path: "/downApp",
      component: () => import("../views/downApp.vue"),
      meta: {
        title: "page.doc255",
      },
    },
    {
      path: "/new",
      component: () => import("../views/new.vue"),
      meta: {
        title: "page.doc255",
      },
    },
    {
      path: "/wallet/payTypes",
      component: () => import("../views/wallet/payTypes.vue"),
      meta: {
        title: "page.doc189",
      },
    },
    {
      path: "/home/cardList",
      component: () => import("../views/team/cardList.vue"),
      meta: {
        title: "page1.doc5",
      },
    },
    {
      path: "/home/cardDetails",
      component: () => import("../views/team/cardDetails.vue"),
      meta: {
        title: "page1.doc6",
      },
    },
    {
      path: "/order",
      component: () => import("../views/order/index.vue"),
      meta: {
        title: "page1.doc5",
      },
    },
    {
      //路由重定向
      path: "*",
      redirect: "/home",
    },
  ],
});
router.beforeEach((to, from, next) => {
  let isLogin = store.state.isLogin;
  let isAddress = store.state.userData.hasBindAddress;
  if (to.meta.address) {
    if (!isAddress) {
      next("/my/fund_password");
      return;
    }
  }
  if (to.meta.auth) {
    if (!isLogin) {
      next("/user/login_reg");
      return;
    }
  }
  const getUserDataPaths = ["/my/index", "/", "/finance/index"];
  if (isLogin && getUserDataPaths.some((item) => item === to.path)) {
    store.dispatch("Get_userData");
  }
  if (to.name === "login" || to.name === "reg" || to.name === "login1") {
    if (to.query.status == undefined) {
      if (isLogin) {
        next("/user/reg");
        return;
      }
    }
  }
  if (to.path === "/team/index" || to.path === "/") {
    store.dispatch("getMessageType");
  }

  //如果是详细页过来的，表明当前页面不需要刷新获取新数据，直接用之前缓存的数据即可
  if (
    from.name == "shop_detail" ||
    from.name == "shop_home" ||
    from.name == "mearch_alliance_view"
  ) {
    to.meta.isBack = true;
  }
  // 跳转前修改福利中心title
  if (to.name == "welfareDetail") {
    to.meta.title = `title.${to.query.title}`;
  }
  next();
});

export default router;
