export default {
  isLogin: false,
  token: "",
  mobile: "",
  email: "",
  userData: {},
  isApp: false,
  appToken: "",
  h5Url: {},
  is_assets: true,
  lang: "",
  areaId: "",
  bank: [],
  msgTypeInfo: null,
  financeIsOpen: false,
  payTypeList: [],
  payType: "",
};
