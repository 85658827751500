const glfooter = () => import(/*! ./footer.vue */ "./footer.vue");
const gltitle = () => import(/*! ./title.vue */ "./title.vue");
const loading = () => import(/*! ./loading */ "./loading.vue");
const PsdInpPopup = () => import(/*! ./PsdInpPopup */ "./PsdInpPopup.vue");
const LevelNotify = () => import(/*! ./LevelNotify */ "./LevelNotify.vue");
const NoData = () => import(/*! ./NoData */ "./NoData.vue");
const InsideHeader = () => import(/*! ./InsideHeader */ "./InsideHeader.vue");
const ComImage = () => import(/*! ./ComImage */ "./ComImage.vue");
const AreaPopup = () => import(/*! ./AreaPopup.vue */ "./AreaPopup.vue");
const coms = {
  glfooter,
  gltitle,
  loading,
  PsdInpPopup,
  LevelNotify,
  NoData,
  InsideHeader,
  ComImage,
  AreaPopup,
};
export default {
  install(Vue) {
    Object.keys(coms).forEach((key) => {
      Vue.component(key, coms[key]);
    });
  },
};
