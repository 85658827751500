export default {
  home: {
    income_info: "/index/income/info", // 收益信息
    level_list: "/level/index/list", // 等级列表
    order_view: "/order/hall/view", // 订单查看
    create: "/order/create", // 订单创建
    order_pay: "/order/payment",
    // order_detail: "order/detail",
    order_record: "/order/record", 
    order_cancel: "/order/cancel", // can't get response of api call in frontend
    // ranking_list: "/ranking/list",
    complete_record: "/index/complete/order/record", // 完成订单记录
    adlists: "/publicity/list", // 广告列表
    article: "/article/detail", // 文章详情
    // proImg: "/product/image/list",
  },
  // lucky: {
  //   create: "luck/create",
  //   reward: "luck/reward",
  //   receive: "luck/receive",
  //   record: "luck/record",
  // },
  task: {
    list: "/task/list",
    receive: "/task/receive", // can't get response of api call in frontend
    // acceptList: "/task/accept/list",
    // accept: "/task/accept",
  },
  doc: {
    version: "/version/list",
  },
  wallet: {
    // info: "/wallet/asset/list",
    // assets: "/wallet/asset/overview",
    // view: "/wallet/asset",
    // priceData: "/wallet/asset/price/data",
    // record: "/wallet/record",
    // type: "/wallet/record/type/list",
    // coinList: "/coin/list",
    // view2: "/wallet/record/detail",
    // cash: "/cash/config",
    // cash_sell: "/cash/sell",
    // cash_buy: "/cash/buy",
    // cash_list: "/cash/currency/list",
    cancel: "/wallet/withdraw/cancel",
    payTypes: "/payType/list",
    newRecord: "/v2/wallet/record",
  },
  income: {
    team: "/income/team/data",
    // data: "/income/data",
    generations: "/income/generations/data",
    teamList: "/team/list",
    assets: "/wallet/asset/overview",
    // create: "/wallet/recharge/create",
    // complete: "/income/complete/list",
    financial: "/financial/view", //? not used?
    isOpen: "/financial/isOpen",
  },
  user: {
    reg: "/signup",
    login: "/signin",
    // cap: "/captcha/get",
    // cap_check: "/captcha/check",
    code_send: "/verify/code/send", // DONE
    // code_check: "/verify/code/check",
    country_list: "/country/list", // DONE
    lang: "/language/list", // DONE
    // forget: "/user/password/login/forget",
    upPw: "/user/password/login/update", // DONE
    // jymm: "/user/password/transaction/update",
    bind_address: "/user/bind/address", // DONE
    wallet_config: "/wallet/withdraw/config", // DONE
    wallet_btn: "/wallet/withdraw", // DONE
    // wallet_record: "/wallet/record",
    wallet_type: "/wallet/record/type/list", // DONE
    wallet_address: "/wallet/recharge/address", // DONE
    upimg: "/upload/img", // DONE
    coinInfo: "/coin/info", // DONE
    bind_account: "/user/info/account/bind", // DONE
    urlGroup: "/url/config/group", // DONE
    // financial: "/financial/order/record",
    account_upData: "/user/info/account/update", // DONE
  },
  member: {
    personal: "/user/info/personal", // DONE
    help_sub: "/article/category/list", // DONE
    help_list: "/article/list", // DONE
    message_type: "/message/type/list", // DONE
    message_list: "/message/list", // DONE
    message_info: "/message/list/info",
    // message_unread: "/message/list/latest_unread", // DONE
    // level_list: "/level/list",
    // url: "/url/config/list",
    nicknameUp: "/user/info/profile/update", // DONE
    // feedback: "/feedback/create",
    // card_update: "/user/card/update",
    // card_info: "/user/card/info",
    // artificial: "/wallet/artificial/withdraw",
    artificial_check: "/wallet/artificial/withdraw/check", // DONE
  },
  // management: {
  //   financial_list: "/financial/product/list",
  //   current_list: "/current/product/list",
  //   financial_detail: "/financial/product/detail",
  //   current_detail: "/current/product/detail",
  //   financial_buy: "/financial/buy",
  //   current_buy: "/current/deposit",
  //   order_detail: "/financial/order/detail",
  //   order_record: "/financial/order/record",
  //   current_redeem: "/current/redeem",
  // },
  // new: {
  //   spilt: "/article/spilt",
  //   downLoad: "index/downLoad/config",
  // },
  order: {
    products: "/product/randoms",
  },
};
