import { Toast } from "vant";
import Vue from "vue";
import axios from "axios";
import store from "@/store";
import router from "@/router";
import common from "@/utils/common";

Vue.use(Toast);

const axiosInstance = axios.create({
  // baseURL: 'http://127.0.0.1:5001/api',
  timeout: 300000,
  headers: {
    post: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }
});
// axios.defaults.timeout = 300000; //请求超时5秒
// axios.defaults.baseURL = "https://api.buygos.online/api";
axios.defaults.baseURL = "http://127.0.0.1:5001/api";
// axios.defaults.baseURL = "http://127.0.0.1:5000/api";
// axios.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded"; //设置post请求是的header信息

// axios.defaults.withCredentials = true

var app = new Vue();
var num = 0;

//http request 拦截器
axiosInstance.interceptors.request.use(
  (config) => {
    let token = store.state.token;
    let lang = "en_US";
    let locale = localStorage.getItem("language") || "en_US";
    lang = locale;
    let timestamp = new Date().getTime();
    // let obj = new Object();
    if (config.isUpload) {
      var obj = new Object();
    } else {
      var obj = Object.assign({}, config.data);
    }
    obj.timestamp = timestamp;
    // obj.apiKey = "test";
    // obj.apiKey = "panda-api";

    if (false) {
    } else {
      obj.apiKey = "XpCLEBlj8f7MlW8v";
    }

    // 去掉签名对象里面的空值，未定义字段
    for (var key in obj) {
      if (obj[key] === "" || undefined === obj[key] || null === obj[key]) {
        delete obj[key];
      }
    }
    obj.sign = common.sign(obj);
    if (token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      // 判断是否是upload模式，如果是则需要改为form-data格式
      if (config.isUpload) {
        let formData = new FormData(); //new一个formData事件
        formData.append("file", config.data.file);
        formData.append("sign", obj.sign);
        formData.append("timestamp", obj.timestamp);
        formData.append("apiKey", obj.apiKey);
        config.data = formData;
        config.headers = {
          "Content-Type": "multipart/form-data",
          system: "android",
          token,
          lang,
        };
      } else {
        config.data = obj;
        config.data = JSON.stringify(config.data);
        config.headers = {
          "Content-Type": "application/json",
          system: "android",
          token,
          lang,
        };
      }
      num++;
      // app.$emit('showloading')
    } else {
      config.data = obj;
      config.headers = {
        "Content-Type": "application/json",
        system: "android",
        lang,
      };
    }
    config.url = 'http://127.0.0.1:5001/api' + config.url;
    // config.url = 'http://127.0.0.1:5000/api' + config.url;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    num--;
    if (num <= 0) {
      app.$emit("closeLoading");
    } else {
      app.$emit("showloading");
    }
    const data = response.data;
    if (data.code === 90001 && router.currentRoute.fullPath !== "/new") {
      router.push("/new");
      return null;
    }
    if (response.data.code === 401) {
      // Toast({
      //   message: '请登录！',
      //   timeout: 1500,
      // });
      store.dispatch("resetVuex");
      // if(!store.state.isApp){
      //   store.dispatch('resetVuex');
      // } else {
      //   let appResult = {
      //     code: response.data.code
      //   };
      //   common.callBackApp(appResult);
      //   return response;
      // }
      // else app提供的方法

      router.push({
        // path:"/user/login",
        path: "/user/login_reg",
        querry: {
          redirect: router.currentRoute.fullPath,
        }, //从哪个页面跳转
      });
      // setTimeout(function(){
      //   router.push({
      //     path:"/user/login",
      //     querry:{redirect: router.currentRoute.fullPath}//从哪个页面跳转
      //   })
      // }, 1500);

      return response;
    }
    // if (response.data.code === -1) {
    //   localStorage.removeItem('token')
    //   router.push({
    //     path:"/login",
    //     querry:{redirect: router.currentRoute.fullPath}//从哪个页面跳转
    //   })
    // }
    return response;
  },
  (err) => {
    app.$emit("closeLoading");
    if (err.code === "ECONNABORTED" && err.message.indexOf("timeout") !== -1) {
      // Toast({
      //   // message: '网络异常，连接超时...',
      //     message:err,
      //   timeout: 1500
      // });
    }
    if (err.message) {
      Toast(err.message);
    }
    return Promise.reject(err);
  }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

function get(url, params = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url, {
        params: params,
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function post(
  url,
  data = {},
  config = {
    isUpload: false,
  }
) {
  return new Promise((resolve, reject) => {
    axiosInstance.post(url, data, config).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
var bus = app;

export default {
  get,
  post,
  bus,
};
