

var _default = {
  name: "Име",
  tel: "Телефон",
  save: "Сачувај",
  confirm: "Потврди",
  cancel: "Откажи",
  delete: "Обриши",
  complete: "Заврши",
  loading: "Учитавање...",
  telEmpty: "Молим вас да унесете телефон",
  nameEmpty: "Молим вас да унесете име",
  nameInvalid: "Неисправно име",
  confirmDelete: "Желите ли заиста да обришете?",
  telInvalid: "Неисправан број телефона",
  vanCalendar: {
    end: "Крај",
    start: "Почетак",
    title: "Календар",
    startEnd: "Почетак/Крај",
    weekdays: ["Нед", "Пон", "Уто", "Сре", "Чет", "Пет", "Суб"],
    monthTitle: function monthTitle(year, month) {
      return year + "/" + month;
    },
    rangePrompt: function rangePrompt(maxRange) {
      return "Изаберите највише " + maxRange + " дана";
    }
  },
  vanCascader: {
    select: "Изабери"
  },
  vanContactCard: {
    addText: "Додај информације за контакт"
  },
  vanContactList: {
    addText: "Додај нови контакт"
  },
  vanPagination: {
    prev: "Претходно",
    next: "Следеће"
  },
  vanPullRefresh: {
    pulling: "Повуците за освежавање...",
    loosing: "Ослободите за освежавање..."
  },
  vanSubmitBar: {
    label: "Укупно:"
  },
  vanCoupon: {
    unlimited: "Неограничено",
    discount: function discount(_discount) {
      return _discount * 10 + "% снижење";
    },
    condition: function condition(_condition) {
      return "Бар " + _condition;
    }
  },
  vanCouponCell: {
    title: "Купон",
    tips: "Нема купона",
    count: function count(_count) {
      return "Имате " + _count + " купона";
    }
  },
  vanCouponList: {
    empty: "Нема купона",
    exchange: "Обмени",
    close: "Затвори",
    enable: "Доступно",
    disabled: "Недоступно",
    placeholder: "Код купона"
  },
  vanAddressEdit: {
    area: "Област",
    postal: "Поштански број",
    areaEmpty: "Молим вас да изаберете област примања",
    addressEmpty: "Адреса не може бити празна",
    postalEmpty: "Погрешан поштански код",
    defaultAddress: "Постави као задану адресу",
    telPlaceholder: "Телефон",
    namePlaceholder: "Име",
    areaPlaceholder: "Област"
  },
  vanAddressEditDetail: {
    label: "Адреса",
    placeholder: "Адреса"
  },
  vanAddressList: {
    add: "Додај нову адресу"
  }
};
export default _default

//# sourceURL=webpack:///./src/public/lang/srRS.js?