import Vue from "vue";
import VueI18n from "vue-i18n";
import ar_AE from "./languages/ar_AE.js";
import bg_BG from "./languages/bg_BG.js";
import ben_BD from "./languages/ben_BD.js";
import de_DE from "./languages/de_DE.js";
import en_US from "./languages/en_US.js";
import es_ES from "./languages/es_ES.js";
import fa_IR from "./languages/fa_IR.js";
import hi_IN from "./languages/hi_IN.js";
import hu_HU from "./languages/hu_HU.js";
import pt_PT from "./languages/pt_PT.js";
import ro_RO from "./languages/ro_RO.js";
import ru_RU from "./languages/ru_RU.js";
import sr_RS from "./languages/sr_RS.js";
import tr_TR from "./languages/tr_TR.js";
import zh_CN from "./languages/zh_CN.js";
import zh_TW from "./languages/zh_TW.js";

Vue.use(VueI18n);

export default new VueI18n({
  locale: localStorage.getItem("language") || "en_US",
  //使用localStorage缓存到本地
  messages: {
    ar_AE,
    //阿拉伯
    bg_BG,
    //保加利亚
    ben_BD,
    //孟加拉语
    de_DE,
    //德语
    en_US,
    //英语
    es_ES,
    //西班牙
    fa_IR,
    //波斯
    hi_IN,
    //印地语
    hu_HU,
    //匈牙利
    pt_PT,
    //葡萄牙
    ro_RO,
    //罗马尼亚语
    ru_RU,
    //俄语
    sr_RS,
    //塞尔维亚语
    tr_TR,
    //土耳其语
    zh_CN,
    //简体中文
    zh_TW,
  },
});
