export default {
  SET_USERDATA(state, data) {
    state.userData = data;
    //state.isLogin = true;
  },
  SET_BANK(state, data) {
    state.bank = data;
    //state.isLogin = true;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_LOGIN(state, isLogin) {
    state.isLogin = isLogin;
  },
  SET_EMAIL(state, email) {
    state.email = email;
  },
  SET_MOBILE(state, mobile) {
    state.mobile = mobile;
  },
  SET_ISAPPINFO(state, payload) {
    (state.isApp = payload), (state.appToken = payload);
  },
  SET_ISASSETS(state, payload) {
    state.is_assets = payload;
  },
  SET_LANG(state, payload) {
    state.lang = payload;
  },
  SET_H5URL(state, data) {
    state.h5Url = data;
  },
  SET_AREAID(state, data) {
    state.areaId = data;
  },
  SET_MSG_TYPE_INFO(state, info) {
    state.msgTypeInfo = info;
  },
  SET_FINANCE_IS_OPEN(state, bool) {
    state.financeIsOpen = bool;
  },
  SET_PAY_TYPE_LIST(state, list) {
    state.payTypeList = list;
  },
  SET_PAY_TYPE(state, payType) {
    state.payType = payType;
  },
};
