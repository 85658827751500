import http from "@/utils/http";
import url from "@/utils/url";
import { Toast } from "vant";

export default {
  async getPayTypes({ commit }) {
    const res = await http.post(url.wallet.payTypes, {});
    if (res.code == 0) {
      if (res.data && res.data.length > 0) {
        commit("SET_PAY_TYPE_LIST", res.data);
        commit("SET_PAY_TYPE", res.data[0].code);
      } else {
        commit("SET_PAY_TYPE_LIST", []);
        commit("SET_PAY_TYPE", "");
      }
    }
  },
  Get_userData({ commit }) {
    http.post(`${url.member.personal}`, {}).then((res) => {
      if (res.code == 0) {
        commit("SET_USERDATA", res.data);
      } else if (res.code == 10007) {
        commit("SET_USERDATA", "");
        commit("SET_TOKEN", "");
        commit("SET_LOGIN", false);
      } else {
        Toast(res.message);
      }
    });
    // http.post(`${url.member.url}`, {}).then((res) => {
    //   if (res.code == 0) {
    //     commit("SET_H5URL", res.data);
    //   } else {
    //     //Toast(res.message);
    //   }
    // });
    // http.post(`${url.member.card_info}`, {}).then((res) => {
    //   if (res.code == 0) {
    //     commit("SET_BANK", res.data);
    //   } else {
    //     //Toast(res.message);
    //   }
    // });
  },
  getMessageType({ commit }) {
    http.post(url.member.message_type, {}).then((res) => {
      if (res.code == 0) {
        commit("SET_MSG_TYPE_INFO", res.data);
      } else {
        Toast(res.message);
      }
    });
  },
  resetVuex({ commit }) {
    commit("SET_LOGIN", false);
    commit("SET_USERDATA", {});
    commit("SET_TOKEN", "");
  },
  // UpadteHtml5Tags({ commit }) {
  //   http.post(url.common.h5page_list, {}).then((res) => {
  //     if (res.code == 0) {
  //       commit("SET_HTML5TAGS", res.data);
  //     } else {
  //       Toast(res.message);
  //     }
  //   });
  // },
  // SetWelfareEyes_2({ commit }, bShow) {
  //   commit("SET_WELFAREEYES_2", bShow);
  // },
  //客服
  CS({ rootState }) {
    _MEIQIA("showPanel");
    let metadata = {
      name: rootState.userData.userId + "",
      comment: rootState.userData.topParentNickname + "",
    };
    _MEIQIA("metadata", metadata);
  },
};
