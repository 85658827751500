<template lang="">
  <div
    class="global-loading"
    :class="stop?'stop':'default'"
    @touchmove="(event)=>{event.preventDefault();}"
  >
    <div class="global-spinner">
      <img
        src="../assets/images/loading.png"
        alt=""
      >
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: "loading",
  props: {
    // 是否阻止滑动和点击
    stop: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.global-loading {
  z-index: 999999999999999999;
  position: fixed;
}
.global-loading * {
  box-sizing: border-box;
}
.global-loading.stop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
}
.global-loading.stop .global-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.global-loading.default {
  width: 1.2rem;
  height: 1.2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.global-loading .global-spinner {
  border-radius: 0.1rem;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  width: 1.2rem;
  height: 1.2rem;
  padding: 0.35rem;
}
.global-loading .global-spinner img {
  width: 0.5rem;
  height: 0.5rem;
  animation: loadingRotate 0.5s linear infinite;
}
@keyframes loadingRotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
