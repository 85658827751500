(function (a, b, c, d, e, j, s) {
  a[d] =
    a[d] ||
    function () {
      (a[d].a = a[d].a || []).push(arguments);
    };
  (j = b.createElement(c)), (s = b.getElementsByTagName(c)[0]);
  j.async = true;
  j.charset = "UTF-8";
  j.src = "https://static.meiqia.com/widget/loader.js";
  s.parentNode.insertBefore(j, s);
})(window, document, "script", "_MEIQIA");
_MEIQIA("entId", "b4bed72785ec915d8a2f6b6f6b9bd8fa");
_MEIQIA("withoutBtn");
_MEIQIA("init");
_MEIQIA("language", "en");
