

var _default = {
  name: "Nome",
  tel: "Telefono",
  save: "Salva",
  confirm: "Conferma",
  cancel: "Annulla",
  delete: "Elimina",
  complete: "Completa",
  loading: "Caricamento...",
  telEmpty: "Si prega di compilare il telefono",
  nameEmpty: "Si prega di compilare il nome",
  nameInvalid: "Nome non valido",
  confirmDelete: "Sei sicuro di voler eliminare?",
  telInvalid: "Numero di telefono non valido",
  vanCalendar: {
    end: "Fine",
    start: "Inizio",
    title: "Calendario",
    startEnd: "Inizio/Fine",
    weekdays: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    monthTitle: function monthTitle(year, month) {
      return year + "/" + month;
    },
    rangePrompt: function rangePrompt(maxRange) {
      return "Scegli non più di " + maxRange + " giorni";
    }
  },
  vanCascader: {
    select: "Seleziona"
  },
  vanContactCard: {
    addText: "Aggiungi informazioni di contatto"
  },
  vanContactList: {
    addText: "Aggiungi nuovo contatto"
  },
  vanPagination: {
    prev: "Precedente",
    next: "Successivo"
  },
  vanPullRefresh: {
    pulling: "Tira per aggiornare...",
    loosing: "Rilascia per aggiornare..."
  },
  vanSubmitBar: {
    label: "Totale: "
  },
  vanCoupon: {
    unlimited: "Illimitato",
    discount: function discount(_discount) {
      return _discount * 10 + "% di sconto";
    },
    condition: function condition(_condition) {
      return "Almeno " + _condition;
    }
  },
  vanCouponCell: {
    title: "Coupon",
    tips: "Nessun coupon",
    count: function count(_count) {
      return "Hai " + _count + " coupon";
    }
  },
  vanCouponList: {
    empty: "Nessun coupon",
    exchange: "Scambia",
    close: "Chiudi",
    enable: "Disponibile",
    disabled: "Non disponibile",
    placeholder: "Codice del coupon"
  },
  vanAddressEdit: {
    area: "Area",
    postal: "CAP",
    areaEmpty: "Si prega di selezionare un'area di ricezione",
    addressEmpty: "L'indirizzo non può essere vuoto",
    postalEmpty: "Codice postale errato",
    defaultAddress: "Imposta come indirizzo predefinito",
    telPlaceholder: "Telefono",
    namePlaceholder: "Nome",
    areaPlaceholder: "Area"
  },
  vanAddressEditDetail: {
    label: "Indirizzo",
    placeholder: "Indirizzo"
  },
  vanAddressList: {
    add: "Aggiungi nuovo indirizzo"
  }
};
export default _default

//# sourceURL=webpack:///./src/public/lang/itIT.js?