

var _default = {
  name: "Име",
  tel: "Телефон",
  save: "Запазване",
  confirm: "Потвърждавам",
  cancel: "Отказ",
  delete: "Изтриване",
  complete: "Завършено",
  loading: "Зареждане...",
  telEmpty: "Моля, попълнете телефонния номер",
  nameEmpty: "Моля, попълнете името",
  nameInvalid: "Невалидно име",
  confirmDelete: "Сигурни ли сте, че искате да изтриете?",
  telInvalid: "Невалиден телефонен номер",
  vanCalendar: {
    end: "Край",
    start: "Начало",
    title: "Календар",
    startEnd: "Начало/Край",
    weekdays: ["Нед", "Пон", "Вт", "Ср", "Чет", "Пет", "Съб"],
    monthTitle: function monthTitle(year, month) {
      return year + "/" + month;
    },
    rangePrompt: function rangePrompt(maxRange) {
      return "Изберете не повече от " + maxRange + " дни";
    }
  },
  vanCascader: {
    select: "Изберете"
  },
  vanContactCard: {
    addText: "Добавете контактна информация"
  },
  vanContactList: {
    addText: "Добавете нов контакт"
  },
  vanPagination: {
    prev: "Предишен",
    next: "Следващ"
  },
  vanPullRefresh: {
    pulling: "Издърпайте за опресняване...",
    loosing: "Пуснете за опресняване..."
  },
  vanSubmitBar: {
    label: "Общо: "
  },
  vanCoupon: {
    unlimited: "Неограничен",
    discount: function discount(_discount) {
      return _discount * 10 + "% отстъпка";
    },
    condition: function condition(_condition) {
      return "Поне " + _condition;
    }
  },
  vanCouponCell: {
    title: "Купон",
    tips: "Няма купони",
    count: function count(_count) {
      return "Имате " + _count + " купона";
    }
  },
  vanCouponList: {
    empty: "Няма купони",
    exchange: "Обмен",
    close: "Затваряне",
    enable: "Достъпен",
    disabled: "Недостъпен",
    placeholder: "Код на купона"
  },
  vanAddressEdit: {
    area: "Район",
    postal: "Пощенски код",
    areaEmpty: "Моля, изберете район за доставка",
    addressEmpty: "Адресът не може да бъде празен",
    postalEmpty: "Грешен пощенски код",
    defaultAddress: "Задай като основен адрес",
    telPlaceholder: "Телефон",
    namePlaceholder: "Име",
    areaPlaceholder: "Район"
  },
  vanAddressEditDetail: {
    label: "Адрес",
    placeholder: "Адрес"
  },
  vanAddressList: {
    add: "Добави нов адрес"
  }
};
export default _default